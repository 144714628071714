import React from 'react'
import { Link } from 'react-router-dom'
import background from 'assets/images/background5.png'
import moto1 from 'assets/images/moto1.svg'
import moto2 from 'assets/images/moto2.svg'
import moto3 from 'assets/images/moto3.svg'

export default function Motorcycles() {
  return (
    <section id='motos' className="flex flex-col justify-center items-center gap-4 bg-image bg-cover bg-center" style={{ backgroundImage: `url(${background})` }}>
<div className='flex flex-col justify-center items-center gap-8 w-11/12 md:w-10/12 my-8'>      
<header>
        <h1 className='text-4xl md:text-7xl font-bold'>MOTOS</h1>
      </header>

      <section className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-center items-center">
        <Link className='flex flex-col gap-4 justify-center items-center' to={'/produtos'}>
          <img src={moto1} alt='' />
          <span className="text-2xl font-bold">BIS</span>
        </Link>

        <Link className='flex flex-col gap-4 justify-center items-center' to={'/produtos'}>
          <img src={moto2} alt='' />
          <span className="text-2xl font-bold">CB 300</span>
        </Link>

        <Link className='flex flex-col gap-4 justify-center items-center' to={'/produtos'}>
          <img src={moto3} alt='' />
          <span className="text-2xl font-bold">BROS</span>
        </Link>
      </section>

      <a href='https://api.whatsapp.com/send?phone=554399689296' target='_blank' rel='noreferrer' className="text-lg rounded-full py-3 px-6 bg-green">
        SIMULE VALORES
      </a>
</div>
    </section>
  )
}
